<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">Asosiy Vosita Hujjat malumotlari22</h3>
          </div>
        </div>
        <div class="card-body">
          <div>
            <div class="d-flex flex-wrap">
              <div class="col-3">
                <!-- {{mainAssetsDocDetails}} -->
                <label>ID</label>
                <v-text-field
                  outlined
                  dense
                  disabled
                  v-model="mainAssetsDocDetails.id"
                >
                </v-text-field>
              </div>

              <div class="col-3">
                <label>Kontragent nomi</label>
                <v-text-field
                  outlined
                  dense
                  disabled
                  v-model="mainAssetsDocDetails.contragent_name"
                >
                </v-text-field>
              </div>

              <div class="col-3">
                <label>Kontragent kontrakt raqami</label>
                <v-text-field
                  outlined
                  dense
                  disabled
                  v-model="mainAssetsDocDetails.contragent_contract_number"
                >
                </v-text-field>
              </div>

              <div class="col-3">
                <label>Sana</label>
                <v-text-field
                  outlined
                  dense
                  disabled
                  v-model="mainAssetsDocDetails.oper_date"
                >
                </v-text-field>
              </div>

              <div class="col-3">
                <label>Reg raqami</label>
                <v-text-field
                  outlined
                  dense
                  disabled
                  v-model="mainAssetsDocDetails.reg_number"
                >
                </v-text-field>
              </div>

              <div class="col-3">
                <label>Izoh</label>
                <v-text-field
                  outlined
                  dense
                  disabled
                  v-model="mainAssetsDocDetails.comment"
                >
                </v-text-field>
              </div>

              <div class="col-3">
                <label>Manba</label>
                <v-text-field
                  outlined
                  dense
                  disabled
                  v-model="mainAssetsDocDetails.payment_source_name"
                >
                </v-text-field>
              </div>
              <div class="col-3">
                <label>Mas’ul </label>
                <v-text-field
                  outlined
                  dense
                  disabled
                  v-model="mainAssetsDocDetails.responsible_name"
                >
                </v-text-field>
              </div>
            </div>
          </div>

          <v-data-table
            :headers="headers"
            hide-default-footer
            :items="mainAssetsDocDetails.details"
            class="elevation-1"
          ></v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  data() {
    return {
      testtable: {},
      measurement2: '',
      name: '',

      headers: [
        {
          text: 'Id',
          value: 'id'
        },
        {
          text: 'Nomenklatura',
          value: 'import_category.name'
        },
        {
          text: 'Holati',
          value: 'product_condition.name'
        },
        {
          text: 'Miqdori',
          value: 'amount'
        },
        {
          text: 'Narxi',
          value: 'price'
        },
        {
          text: 'Jami',
          value: 'total'
        },
        {
          text: 'Nds',
          value: 'nds'
        },
        {
          text: 'Har bir element uchun nds',
          value: 'nds_per_item'
        },
        {
          text: 'Nds summa',
          value: 'nds_summa'
        },
        {
          text: 'Jami nds summa',
          value: 'total_withnds_summa'
        }
      ]
    }
  },
  beforeCreate() {
    // this.$store.dispatch("mainAssetsTaken", 1);
    this.$store.dispatch('mainAssetsTaken')
    this.$store.dispatch('mainAssets')
    this.$store.dispatch('getMeasurements')
  },
  computed: {
    mainAssetsDoc() {
      return this.$store.state.requests.mainAssetsTaken.results
    },
    getData() {
      return this.$store.state.requests.measurements
    },
    mainAssetsDocDetails() {
      return this.$store.state.requests.mainAssetsTaken.results.find(
        (obj) => obj.id == this.$route.params.id
      )
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: ' Moliya' },
      { title: 'Asosiy Vositalar Hujjati' }
    ])
  }
}
</script>

<style scoped>
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
}
</style>
